import React from "react"
import { graphql, Link } from "gatsby"

export const query = graphql`
query IndexQuery {
  allMarkdownRemark {
    edges {
      node {
        id
        frontmatter {
          path
          title
          showIndex
        }
      }
    }
  }
}
`

interface Props {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          id: string
          frontmatter: Frontmatter
        }
      }[]
    }
  }
}

export default ({data}: Props) => {
  const posts = data.allMarkdownRemark.edges


  return (<div>
    {posts.map(({node: post}) => {
      const {frontmatter} = post;
      return (
        <div key={post.id}>
          <h2>
            <Link to={frontmatter.path}>
              {frontmatter.title}
            </Link>
          </h2>
          <p>showIndex? {frontmatter.showIndex}</p>
        </div>
      );
    })}
  </div>)
}
